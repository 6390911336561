import classnames from "classnames";
import React, {useCallback, useRef, ChangeEvent, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";

import {Icon} from "../images";
import {Button, InputRow} from "../input";
import {DropdownCallback, useDropdown} from "../input/dropdown";
import {MediaLibraryModalArgs, useMediaLibraryModal} from "../../modals/media/media-library";
import {Span2} from "../text";
import {Modal, useModal} from "../../modals/new";
import CanvaMediaSelector from "./canva-media-selector";
import {HoverTooltip} from "../tooltip";
import {CANVA_INTEGRATION} from "../../data/canva";
import {Loading} from "../loading";
import {useToast} from "../../toast";
import {useMyUser} from "../../data";

import styles from "./media-button.module.scss";

export function MediaButton<T>({
	mediaLibrary,
	onFileChange,
	onCanvaSelect,
	loading,
}: {
	mediaLibrary: MediaLibraryModalArgs<T>;
	onFileChange: (file: File) => void;
	loading?: boolean;
	onCanvaSelect: (id: string) => void;
}) {
	const navigate = useNavigate();
	const toast = useToast();
	const inputRef = useRef<HTMLInputElement>(null);
	const {data: canvaIntegrationData, loading: loadingCanvaIntegration} = useQuery(CANVA_INTEGRATION, {
		onError: err => {
			toast({
				color: "red",
				text: err.message,
			});
		},
	});
	const {org} = useMyUser();
	const upcomingFreatures = useMemo(() => org.features.upcomingFeatures, [org]);
	const connected = useMemo(() => canvaIntegrationData?.canvaIntegration?.connected ?? false, [
		canvaIntegrationData,
	]);
	const mediaLibraryModal = useMediaLibraryModal(mediaLibrary);
	const {open: openCanva, close, modal} = useModal({
		size: "large",
		maxWidth: "936px",
		padding: "0",
	});

	const handleOnClick = useCallback(() => inputRef.current?.click(), []);
	const handleFileChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (!e.target.files) {
				return;
			}

			const currentFile = e.target.files[0];

			onFileChange(currentFile);

			if (inputRef?.current) {
				inputRef.current.value = "";
			}
		},
		[onFileChange]
	);

	const popup = useCallback(
		({close}: DropdownCallback) => (
			<div>
				<div
					className={styles.menuItem}
					onClick={() => {
						close();
						mediaLibraryModal.open();
					}}
				>
					<Icon icon="photo-library" />
					<Span2>Media Library</Span2>
				</div>
				<div className={styles.menuItem} onClick={handleOnClick}>
					<Icon icon="upload" />
					<Span2>Upload Image</Span2>
				</div>
				{upcomingFreatures && (
					<div
						className={classnames(styles.menuItem, loadingCanvaIntegration && styles.disabled)}
						onClick={() => {
							if (!connected) {
								navigate("/settings/integrations");
								return;
							}
							close();
							openCanva();
						}}
					>
						{loadingCanvaIntegration && <Loading size="small" position="absolute" />}
						<Icon icon="canva" />
						<Span2>Import from Canva</Span2>
					</div>
				)}
			</div>
		),

		[
			handleOnClick,
			mediaLibraryModal,
			openCanva,
			connected,
			loadingCanvaIntegration,
			navigate,
			upcomingFreatures,
		]
	);
	const {portal, reference, open} = useDropdown({
		popup,
		portalClassName: styles.menu,
	});

	return (
		<>
			{portal}

			<input
				accept={`image/*`}
				type="file"
				ref={inputRef}
				onChange={handleFileChange}
				className={styles.inputFile}
			/>
			{upcomingFreatures && (
				<Modal
					modal={modal}
					className={styles.canvaMediaModal}
					header={
						<InputRow position="between" className={styles.canvaModalTitle}>
							<div className={styles.canvaMediaHeader}>
								<img src={"/svgs/canva.svg"} />
								<h2>Import from Canva</h2>
							</div>
							<Icon icon="close" onClick={close} />
						</InputRow>
					}
				>
					<CanvaMediaSelector close={close} onSelect={onCanvaSelect} />
				</Modal>
			)}
			<div className={"space"}>
				<HoverTooltip text={"Select Media"} positions={["top"]}>
					<Button
						ref={reference}
						icon="photo"
						loading={loading}
						color="black"
						invert
						border={false}
						onClick={open}
					/>
				</HoverTooltip>
			</div>
		</>
	);
}
