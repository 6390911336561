import {DocumentNode, FieldPolicy, InMemoryCache, defaultDataIdFromObject} from "@apollo/client";
import unionBy from "lodash/unionBy";

import {
	ADD_CUSTOM_RSS_FEED,
	ADD_RSS_FEED,
	ADD_COMPANY,
	ADD_USER,
	CATEGORY_FRAGMENT,
	CONNECT_COMPANY,
	CREATE_CATEGORY,
	CREATE_COLLECTION,
	CREATE_GROUP,
	CREATE_SHARE,
	CREATE_COMPANY_SHARE,
	DEACTIVATE_USERS,
	DELETE_RSS_FEED,
	DELETE_SHARE_EVENT,
	DISCONNECT_USER_SOCIAL,
	GROUP_FRAGMENT,
	HIDE_SUGGESTED_SHARE,
	REFRESH_CODE,
	RSS_FEED_FRAGMENT,
	SAVE_COLLECTION,
	UPDATE_SELF_ENROLLMENT,
	UPDATE_ORG,
	UPLOAD_USERS,
	COMPANY_FRAGMENT,
	USER_FRAGMENT,
	SCHEDULE_SHARE_EVENT,
	DISCONNECT_SALESFORCE,
	SHARE_IMMEDIATELY,
	SET_SALESFORCE_INTEGRATION,
	SYNC_SALESFORCE_LEADS,
	SET_SLACK_CHANNELS,
	SET_HUBSPOT_INTEGRATION,
	SYNC_HUBSPOT_CONTACTS,
	DISCONNECT_HUBSPOT,
} from "./data";
import {COLLECTION_FRAGMENT, DELETE_COLLECTION, DUPLICATE_COLLECTION} from "./data/collection";
import {DUPLICATE_COMPANY_SHARE, DUPLICATE_SHARE, SCHEDULE_QUEUE, UPDATE_SHARE_EVENT} from "./data/share";
import {SUGGEST_RSS_FEED_POST, SUGGEST_URL} from "./data/rssFeed";
import {SET_TEAMS_CHANNELS} from "./data/teams";
import {HIDE_FEED_POST, HIDE_RSS_FEED_POST, SUGGESTED_SHARES_FRAGMENT} from "./data/feed";
import {
	DELETE_IMAGE,
	DELETE_VIDEO,
	ORG_IMAGE_FRAGMENT,
	ORG_VIDEO_FRAGMENT,
	SET_URL_FOR_ORG_VIDEO,
	UPLOAD_MEDIA_IMAGE,
} from "./data/media";
import {
	CREATE_SHUFFLED_COMMENT,
	DELETE_SHUFFLED_COMMENT,
	DELETE_SHUFFLED_COMMENTS,
	GET_SHUFFLED_COMMENTS,
	SHUFFLED_COMMENT_FRAGMENT,
} from "./data/social-shuffle";
import {
	CONTEST_FRAGMENT,
	CREATE_CONTEST,
	DELETE_CONTEST,
	DISCONNECT_TREMENDOUS,
	MARK_CONTEST_COMPLETE,
	SEND_TREMENDOUS_REWARD,
	UPDATE_CONTEST,
} from "./data/contest";
import {DISCONNECT_CANVA} from "./data/canva";

const paginatedCache = (keyArgs: FieldPolicy["keyArgs"]): FieldPolicy => ({
	keyArgs,
	merge: (existing, incoming) => ({
		items: unionBy(existing?.items ?? [], incoming.items ?? [], "__ref"),
		cursor: incoming.cursor,
	}),
	read: existing => existing,
});

export const cache: InMemoryCache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				collections: paginatedCache(["groups", "sort", "search", "status", "owner", "start", "end"]),
				users: paginatedCache(["limit", "search", "sort", "filter"]),
				companyShareEvents: paginatedCache(["sort", "ids", "shareIds", "start", "end", "search", "status"]),
				userShareEvents: paginatedCache(["sort", "ids", "shareIds", "start", "end", "search", "status"]),
				shareEvents: paginatedCache(["ids"]),
				contestLeaderboard: paginatedCache(["contestId"]),
				contests: paginatedCache(["startDate", "endDate", "filter"]),
				canvaDesigns: paginatedCache(["search", "order"]),
				imageList: {
					keyArgs: ["search", "createdBy"],
					merge: (existing = {}, incoming) => ({
						...incoming,
						items: unionBy(existing.items ?? [], incoming.items ?? [], "__ref"),
					}),
					read: existing => existing,
				},
				videoList: {
					keyArgs: ["search", "createdBy"],
					merge: (existing = {}, incoming) => ({
						...incoming,
						items: unionBy(existing.items ?? [], incoming.items ?? [], "__ref"),
					}),
					read: existing => existing,
				},
				rssFeedPosts: paginatedCache(false),
				suggestedPosts: paginatedCache(["sort", "companyId", "type"]),
				companyShares: paginatedCache(["sort", "companyId", "type"]),
				myHub: paginatedCache(["sort", "type"]),
				suggestedShares: paginatedCache(["id"]),
				usersLeaderBoard: paginatedCache(["search", "groupId", "start", "end", "global"]),
				usersAdoption: paginatedCache(["groups", "status"]),
				user: {
					read: (_, {args, toReference}) => toReference({__typename: "User", id: args?.id}),
				},
				group: {
					read: (_, {args, toReference}) => toReference({__typename: "Group", id: args?.id}),
				},
			},
		},
	},
	dataIdFromObject(responseObject) {
		switch (responseObject.__typename) {
			case "UserLeaderboard":
				return `UserLeaderboard:${responseObject.userId}`;
			case "UserAdoption":
				return `UserAdoption:${responseObject.userId}`;
			case "Share":
				//we don't want to cache the share when it is loaded as part of a share event
				//the caching is not working properly and the share data is overridden when the share event is updated
				return responseObject.opengraphs && !responseObject.shareEvents
					? undefined
					: defaultDataIdFromObject(responseObject);
			default:
				return defaultDataIdFromObject(responseObject);
		}
	},
});

export const deleteItem = (fieldName: string, mutationName: string) => (cache, result) => {
	const data = result?.data[mutationName];
	if (data == null) return;

	let ids: number[];
	if (Array.isArray(data)) {
		// array of ids
		const hasIdKey = data.some(item => typeof item === "object" && "id" in item);
		if (!hasIdKey) {
			ids = data;
		}
		// array of objects with id
		else {
			ids = data.map(item => item?.id);
		}
	} else {
		ids = [data].map(item => item?.id);
	}
	ids.forEach(id => cache.evict({id: `${fieldName}:${id}`}));
	cache.gc();
};

export const addPageItem = (
	field: string,
	fragment: DocumentNode,
	fragmentName: string,
	mutationName: string
) => (cache, result) => {
	const data = result?.data[mutationName];
	if (data == null) return;
	const id = data.id;
	if (!id) return;
	const newRef = cache.writeFragment({data, fragment, fragmentName});
	cache.modify({
		fields: {
			[field]: (current = [], {readField}) =>
				current?.some(ref => readField("id", ref) === id) ? current : [...current, newRef],
		},
	});
};

export const addPaginatedItem = (
	field: string,
	fragment: DocumentNode,
	fragmentName: string,
	mutationName: string
) => (cache, result) => {
	const data = result?.data[mutationName];
	if (data == null) return;
	const id = data.id;
	if (!id) return;
	const newRef = cache.writeFragment({data, fragment, fragmentName});
	cache.modify({
		fields: {
			[field]: (current: {cursor: string; items: []}, {readField}) => ({
				...current,
				items: current?.items?.some(ref => readField("id", ref) === id)
					? current.items
					: [newRef, ...current.items],
			}),
		},
	});
};

const invalidateRootField = (fieldName: string | string[]) => cache => {
	if (typeof fieldName === "string") {
		cache.evict({fieldName});
		cache.gc();
	} else {
		fieldName.forEach(fieldName => {
			cache.evict({fieldName});
			cache.gc();
		});
	}
};

export const addPageItems = (
	field: string,
	fragment: DocumentNode,
	fragmentName: string,
	mutationName: string
) => (cache, result) => {
	const data = result?.data[mutationName];
	if (data == null) return;
	const newRefs = {};
	data.forEach(item => {
		const id = item.id;
		if (!id) return undefined;
		newRefs[id] = cache.writeFragment({data: item, fragment, fragmentName});
	});
	cache.modify({
		fields: {
			[field]: (current = [], {readField}) => {
				current.forEach(ref => {
					const id = readField("id", ref);
					delete newRefs[id];
				});
				const refs = Object.values(newRefs);
				return refs.length ? [...current, ...refs] : current;
			},
		},
	});
};

export const replaceItem = (field: string, mutationName: string) => (cache, result) => {
	const data = result?.data[mutationName];
	if (data == null) return;

	cache.modify({
		fields: {
			[field]: () => data,
		},
	});
};

const updatePostShuffledCommentsCount = (cache, queuedUrlId) => {
	cache.modify({
		id: cache.identify({__typename: "Post", id: queuedUrlId}),
		fields: {
			shuffledCommentsCount() {
				const data = cache.readQuery({
					query: GET_SHUFFLED_COMMENTS,
					variables: {queuedUrlId},
				});

				return data?.shuffledComments?.length;
			},
		},
	});
};

const handleCreateShuffledComment = (cache, result) => {
	const queuedUrlId = result?.data?.createShuffledComment?.queuedUrlId;
	const queryIdentifier = `shuffledComments({"queuedUrlId":${queuedUrlId}})`;

	addPageItem(
		queryIdentifier,
		SHUFFLED_COMMENT_FRAGMENT,
		"ShuffledCommentFields",
		"createShuffledComment"
	)(cache, result);

	updatePostShuffledCommentsCount(cache, queuedUrlId);
};

const handleDeleteShuffledComments = (cache, result) => {
	const queuedUrlId = result?.data?.deleteShuffledComments?.queuedUrlId;
	const queryIdentifier = `shuffledComments({"queuedUrlId":${queuedUrlId}})`;

	invalidateRootField(queryIdentifier)(cache);
	cache.modify({
		id: cache.identify({__typename: "Post", id: queuedUrlId}),
		fields: {
			shuffledCommentsCount() {
				return 0;
			},
		},
	});
};

const handleDeleteShuffledComment = (cache, result) => {
	deleteItem("ShuffledComment", "deleteShuffledComment")(cache, result);

	const queuedUrlId = result?.data?.deleteShuffledComment?.queuedUrlId;
	updatePostShuffledCommentsCount(cache, queuedUrlId);
};

export const multipleCacheUpdates = (...updates) => (cache, result) => {
	updates.forEach(update => update(cache, result));
};
const updateContest = (cache, result) => {
	const data = result?.data?.sendTremendousReward;
	if (data == null) return;
	const id = data.id;
	const type = data.type;
	if (type === "success") {
		cache.modify({
			id: cache.identify({__typename: "Contest", id: id}),
			fields: {
				complete() {
					return true;
				},
			},
		});
	}
};

export const getCacheUpdate = (mutation: DocumentNode) => {
	// prettier-ignore
	const update =
		mutation === CREATE_SHARE ? invalidateRootField("userShareEvents"):
		mutation === CREATE_COMPANY_SHARE ? invalidateRootField(["companyShareEvents","companyTwitterAnalytics", "companyTwitterAnalyticsCards", "companyShares"]):
		mutation === DUPLICATE_SHARE ? invalidateRootField("userShareEvents"):
		mutation === DUPLICATE_COMPANY_SHARE ? invalidateRootField(["companyShareEvents", "companyTwitterAnalytics", "companyTwitterAnalyticsCards"]):
		mutation === UPDATE_ORG ? invalidateRootField("usersLeaderBoard"):
		mutation === SCHEDULE_SHARE_EVENT ? invalidateRootField("userShareEvents"):
		mutation === SCHEDULE_QUEUE ? invalidateRootField("userShareEvents"):
		mutation === UPDATE_SHARE_EVENT ? invalidateRootField("userShareEvents"):
		mutation === CREATE_COLLECTION ? addPaginatedItem("collections", COLLECTION_FRAGMENT, "CollectionFields", "createCollection"):
		mutation === DUPLICATE_COLLECTION ? addPaginatedItem("collections", COLLECTION_FRAGMENT, "CollectionFields", "duplicateCollection") :
		mutation === ADD_USER ? addPaginatedItem("users", USER_FRAGMENT, "UserFields", "addUser"):
		mutation === CREATE_GROUP ? addPageItem("groups", GROUP_FRAGMENT, "GroupFields", "createGroup"):
		mutation === CREATE_CATEGORY ? addPageItem("categories", CATEGORY_FRAGMENT, "CategoryFields", "createCategory"):
		mutation === UPLOAD_USERS ? addPaginatedItem("users", USER_FRAGMENT, "UserFields", "uploadUsers") :
		mutation === ADD_RSS_FEED ? multipleCacheUpdates(
			addPageItem("rssFeeds", RSS_FEED_FRAGMENT, "RssFeedFields", "addRssFeed"),
			invalidateRootField("userFeeds")
		) :
		mutation === ADD_CUSTOM_RSS_FEED ? addPageItem("rssFeeds", RSS_FEED_FRAGMENT, "RssFeedFields", "addCustomRssFeed") :
		mutation === SUGGEST_URL ? addPaginatedItem("suggestedShares", SUGGESTED_SHARES_FRAGMENT, "SuggestedSharesFields", "suggestUrl") :
		mutation === UPDATE_SELF_ENROLLMENT ? replaceItem("selfEnrollment", "updateSelfEnrollment") :
		mutation === REFRESH_CODE ? replaceItem("selfEnrollment", "refreshCode") :
		mutation === SAVE_COLLECTION ? invalidateRootField("scheduledCollections"):
		mutation === DEACTIVATE_USERS ? deleteItem("User", "deactivateUsers") :
		mutation === DELETE_COLLECTION ? deleteItem("Collection", "deleteCollection") :
		mutation === DELETE_RSS_FEED ? deleteItem("RssFeed", "deleteRssFeed") :
		mutation === DELETE_SHARE_EVENT ? deleteItem("ShareEvent", "deleteShareEvent") :
		mutation === HIDE_SUGGESTED_SHARE ? deleteItem("SuggestedShare", "hideSuggestedShare") :
		mutation === HIDE_RSS_FEED_POST ? deleteItem("RssFeedPost", "hideRssFeedPost") :
		mutation === HIDE_FEED_POST ? multipleCacheUpdates(deleteItem("FeedPost", "hideFeedPost"), invalidateRootField("userShareEvents")) :
		mutation === DELETE_IMAGE ? deleteItem("OrgImage", "deleteImage") :
		mutation === DELETE_VIDEO ? deleteItem("OrgVideo", "deleteVideo") :
		mutation === UPLOAD_MEDIA_IMAGE ? addPaginatedItem("imageList", ORG_IMAGE_FRAGMENT, "OrgImageFields", "uploadMediaImage") :
		mutation === SET_URL_FOR_ORG_VIDEO ? addPaginatedItem("videoList", ORG_VIDEO_FRAGMENT, "OrgVideoFields", "setUrlForOrgVideo") :
		mutation === ADD_COMPANY ? addPageItem("companyPages", COMPANY_FRAGMENT, "CompanyFields", "addCompany"):
		mutation === CREATE_SHUFFLED_COMMENT ? handleCreateShuffledComment :
		mutation === CREATE_CONTEST? multipleCacheUpdates(
			addPaginatedItem("contests", CONTEST_FRAGMENT, "ContestFields", "createContest"),
			invalidateRootField(["activeContestCount", "users"])
		):
		mutation === MARK_CONTEST_COMPLETE? invalidateRootField("activeContestCount"):
		mutation === SEND_TREMENDOUS_REWARD? multipleCacheUpdates(
			updateContest,
			invalidateRootField("activeContestCount")
		):
		mutation === DELETE_CONTEST? multipleCacheUpdates(deleteItem("Contest", "deleteContest"), invalidateRootField(["activeContestCount", "users"])):
		mutation === UPDATE_CONTEST? invalidateRootField("contestLeaderboard"):
		mutation === DELETE_SHUFFLED_COMMENT ? handleDeleteShuffledComment :
		mutation === DELETE_SHUFFLED_COMMENTS ? handleDeleteShuffledComments :
		mutation === CONNECT_COMPANY ? addPageItem("companyPages", COMPANY_FRAGMENT, "CompanyFields", "connectCompany") :
		mutation === DISCONNECT_USER_SOCIAL ? replaceItem("userConnectionPages", "disconnect")  :
		mutation === SET_SLACK_CHANNELS ? invalidateRootField("slackChannels") :
		mutation === SET_SALESFORCE_INTEGRATION ? invalidateRootField("salesforceIntegration") :
		mutation === SYNC_SALESFORCE_LEADS ? invalidateRootField("salesforceIntegration") :
		mutation === DISCONNECT_SALESFORCE ? invalidateRootField("salesforceIntegration") :
        mutation === SET_HUBSPOT_INTEGRATION ? invalidateRootField("hubspotIntegration") :
        mutation === SYNC_HUBSPOT_CONTACTS ? invalidateRootField("hubspotIntegration") :
        mutation === DISCONNECT_HUBSPOT ? invalidateRootField("hubspotIntegration") :
        mutation === DISCONNECT_TREMENDOUS ? invalidateRootField("tremendousIntegration") :
        mutation === DISCONNECT_CANVA ? invalidateRootField("canvaIntegration") :
		mutation === SUGGEST_RSS_FEED_POST ? invalidateRootField("suggestedShares") :
		mutation === SET_TEAMS_CHANNELS ? invalidateRootField("teamsChannels") :
		mutation === SHARE_IMMEDIATELY ? invalidateRootField(["myHub", "userShareEvents", "companyShares", "contests"]) :
		undefined;
	if (update) return {update};
	return {};
};
