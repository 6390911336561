import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {Dayjs} from "dayjs";

import {User} from "./user";
import {Option} from "../components/input";

export interface Group {
	id: number;
	name: string;
	active: boolean;
	approveCollections: boolean;
	createCollections: boolean;
	sendAll: boolean;
	addContent: boolean;
	setPrimaryAdmin: boolean;
	manageGroups: boolean;
	manageSocialMediaConnection: boolean;
	editCompanyBranding: boolean;
	manageCompanyIds: number[];
	queueCompanyIds: number[];
	addUserGroupIds: number[];
	queueGroupIds: number[];
	resendLoginGroupIds: number[];
	users: Partial<User>[];
	queues: number;
	vas: number;
	lastCollection?: Dayjs;
}

export const GROUP_FRAGMENT = gql`
	fragment GroupFields on Group {
		id
		name
		active
		approveCollections
		createCollections
		sendAll
		addContent
		manageGroups
		manageCompanyIds
		manageSocialMediaConnection
		editCompanyBranding
		queueCompanyIds
		addUserGroupIds
		queueGroupIds
		resendLoginGroupIds
		approveCollections
		setPrimaryAdmin
		lastCollection
		users {
			id
			firstName
			lastName
			role
			virtualAssistant
			profilePic
		}
		queues
		vas
	}
`;

export const GET_GROUPS = gql`
	query {
		groups {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;
export const GET_SIMPLE_GROUPS = gql`
	query SimpleGroups {
		groups {
			name
			id
		}
	}
`;

export const CREATE_GROUP = gql`
	mutation CreateGroup($name: String!) {
		createGroup(name: $name) {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;

export const UPDATE_GROUP = gql`
	mutation UpdateGroup($id: PositiveInt!, $changes: UpdateGroup!) {
		updateGroup(id: $id, changes: $changes) {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;

export const DELETE_GROUP = gql`
	mutation DeleteGroup($id: PositiveInt!) {
		deleteGroup(id: $id) {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;

export const useSimpleGroup = (): {groups: Option<number>[]; loading: boolean} => {
	const {data, loading, error} = useQuery(GET_SIMPLE_GROUPS);

	const groups = useMemo(() => {
		if (loading || error || !data?.groups) {
			return [];
		}

		return data.groups.map(u => ({value: Number(u.id), label: u?.name ?? "Loading"}));
	}, [data, loading, error]);

	return {groups, loading};
};

export const useGroupList = (): {groups: Option<number>[]; loading: boolean} => {
	const {data, loading, error} = useQuery(GET_GROUPS);

	const groups = useMemo(() => {
		if (loading || error || !data?.groups) {
			return [];
		}

		return data.groups
			.filter(u => u?.name !== "Everyone")
			.map(u => ({value: Number(u.id), label: u?.name ?? "Loading"}));
	}, [data, loading, error]);

	return {groups, loading};
};

export const useAllGroups = (): {groups: Option<number>[]; loading: boolean} => {
	const {data, loading, error} = useQuery(GET_GROUPS);
	return useMemo(() => {
		if (loading || error || !data?.groups) {
			return {groups: [], loading: loading};
		}
		return {
			groups: data.groups.map(u => ({
				value: Number(u.id),
				label: u?.name ?? "Loading",
			})),
			loading: loading,
		};
	}, [data, loading, error]);
};

export const useComplexGroup = (): Group[] => {
	const {data, loading, error} = useQuery(GET_GROUPS);
	return useMemo(() => {
		if (loading || error || !data?.groups) {
			return [];
		}
		return data.groups;
	}, [data, loading, error]);
};
