import {useCallback, useEffect, useMemo, useState} from "react";
import classNames from "classnames";

import {ToggleGroup} from "../../components/toggle";
import {
	Button,
	Checkbox,
	File,
	Option,
	SearchableSelect,
	Select,
	Text,
	Validate,
	required,
	validEmail,
} from "../../components/input";
import {ModalHook, NewModal as Modal} from "../../modals";
import {ADD_USER, NewUser, UPLOAD_USERS, roleOptions, useGroupList} from "../../data";
import {useMutationToast, useToast} from "../../toast";
import {P} from "../../components/text";
import {validMaxLength} from "../../components/input/validation";
import {ModalData} from "../../modals/new";

import styles from "./user.module.scss";

export interface AddUserModalHook<T> extends Omit<ModalHook<T>, "open"> {
	open: (multiple?: boolean) => void;
}

export interface AddUserModalProps {
	onEnroll: () => void;
	modal: ModalData;
	viewMultiple?: boolean;
}

const options: Option<boolean>[] = [
	{value: false, label: "Individual"},
	{value: true, label: "Multiple"},
];

const clearUser = (): NewUser => ({
	firstName: "",
	lastName: "",
	email: "",
	groups: [],
	virtualAssistant: false,
});

export const AddUserModal = ({onEnroll, modal, viewMultiple}: AddUserModalProps): React.ReactElement => {
	const {groups: groupList} = useGroupList();
	const [multiple, setMultiple] = useState(viewMultiple ?? false);
	const [newUser, setNewUser] = useState<NewUser>(clearUser);
	const [valid, setValid] = useState(true);
	const toast = useToast();
	const [uploadUsers, {loading: uploading}] = useMutationToast(UPLOAD_USERS, {
		onCompleted: () => toast({text: "Your user upload has successfully completed", color: "green"}),
	});
	const [addUser, {loading: adding}] = useMutationToast(ADD_USER);

	useEffect(() => {
		if (modal.open) setMultiple(viewMultiple ?? false);
	}, [modal.open, viewMultiple]);
	useEffect(() => {
		setNewUser(clearUser);
	}, [modal.open]);

	const inputFunc = useMemo(
		() => <X extends keyof NewUser>(key: X) => ({
			value: newUser[key],
			onChange: (value: NewUser[X]) => setNewUser(c => ({...c, [key]: value})),
		}),
		[newUser]
	);
	const handleFile = ([file]: File[]) => uploadUsers({variables: {file: file}}).then(modal.close);
	const handleAdd = () =>
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		addUser({variables: {newUser: {...newUser, role: newUser.role!.toUpperCase()}}}).then(modal.close);

	const inputFuncPrettied = useCallback(
		(key, options) => {
			const {value, onChange} = inputFunc(key);
			return {
				value: options.filter(({value: optionValue}) =>
					Array.isArray(value) ? value.includes(optionValue) : optionValue === value
				),
				onChange: items => {
					onChange(items.map(({value}) => value));
				},
			};
		},
		[inputFunc]
	);
	return (
		<Modal title="User Invitation" modal={modal}>
			<ToggleGroup options={options} value={multiple} onChange={setMultiple} />
			{multiple ? (
				<>
					<P>
						Upload a file containing multiple users including their name and groups that you would like them
						added to. To get started, download <a href="/User_Upload.csv">our CSV file</a>.
					</P>
					<P>
						To add administrators, write <code>admin</code> in the <code>roles</code> field. To add e-mail
						subscribers, write <code>external</code> in the <code>roles</code> field. For all other users,
						write <code>user</code> in the <code>roles</code> field. To add users to groups, seperate each
						group name with a semicolon (<code>;</code>) in the <code>groups</code> field.
					</P>
					<File what="a file" onFile={handleFile} accept={{"text/csv": [".csv"]}} loading={uploading} />
				</>
			) : (
				<Validate setStatus={setValid}>
					<Text label="First Name" {...inputFunc("firstName")} validate={[required, validMaxLength(200)]} />
					<Text label="Last Name" {...inputFunc("lastName")} validate={[required, validMaxLength(100)]} />
					<Text label="Company Email" {...inputFunc("email")} validate={[required, validEmail]} />
					<Select options={roleOptions} label="Role" {...inputFunc("role")} validate={required} />
					<Checkbox label="Virtual Assistant" {...inputFunc("virtualAssistant")} />
					<SearchableSelect
						pillColor="blue"
						className={classNames(styles.groupSelect, "space")}
						isMulti
						options={groupList}
						label="Groups"
						{...inputFuncPrettied("groups", groupList)}
					/>
					<Button large value="Send Invite" disabled={!valid} loading={adding} onClick={handleAdd} />
				</Validate>
			)}
			<Button invert large value="Send Self-Enrollment Link" onClick={onEnroll} disabled={adding} />
		</Modal>
	);
};
