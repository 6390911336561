import {ReactElement, useCallback, useMemo, useState} from "react";

import {
	Button,
	Checkbox,
	DropdownButton,
	File,
	Group,
	GroupedDropdown,
	IconOption,
	InputHeader,
	InputRow,
	Separator,
	SmallButton,
	Text,
	toggle,
	triState,
} from "../../components/input";
import {User} from "./user";
import {
	DEACTIVATE_USERS,
	GET_USERS,
	REINVITE_USERS,
	REMIND_RECONNECT,
	usePendingUsers,
	useExpiredSocialMediaUsers,
	User as UserType,
	loadUser,
	roleOptions,
	useGroupList,
	useMyUser,
	User as UserQuery,
	userAccountNames,
} from "../../data";
import {usePageScroll} from "../../layout";
import {useDebounce} from "../../debounce";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {Icon} from "../../components/images";
import {P, Span, Span1} from "../../components/text";
import {useToast, useMutationToast} from "../../toast";
import {Selection, useSelectionConfirmModal, useNewModal as useModal} from "../../modals";
import {EnrollModal} from "./enroll-modal";
import {AddUserModal} from "./add-user-modal";
import {useToastApi} from "../../api";
import {config} from "../../config";

import styles from "./user.module.scss";

const renderReinvite = (selections: Selection[]) => (
	<P>
		You are about to re-send{" "}
		<Span color="pink">
			{selections.length} {selections.length === 1 ? "invitation" : "invitations"}
		</Span>{" "}
		for users that have not yet accepted their Welcome to Clearview Social email. You can take a look at the
		users below before sending them all out. You can only reinvite up to 100 users at a time.
	</P>
);

const renderReconnect = (selections: Selection[]) => (
	<P>
		You are about to re-send{" "}
		<Span color="pink">
			{selections.length} {selections.length === 1 ? "reminder" : "reminders"}
		</Span>{" "}
		for users that have disconnected or expired social media accounts. You can take a look at the users below
		before sending them all out.
	</P>
);

const sortOptions = [
	{
		value: ["alphabetical", "asc"],
		label: "A to Z Name",
		sort: (a: UserType, b: UserType) =>
			a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName),
	},
	{
		value: ["alphabetical", "desc"],
		label: "Z to A Name",
		sort: (a: UserType, b: UserType) =>
			b.lastName.localeCompare(a.lastName) || b.firstName.localeCompare(a.firstName),
	},
	{
		value: ["last_shared", "asc"],
		label: "Last Shared Ascending",
		sort: (a: UserType, b: UserType) => (a.lastShared?.valueOf() ?? 0) - (b.lastShared?.valueOf() ?? 0),
	},
	{
		value: ["last_shared", "desc"],
		label: "Last Shared Descending",
		sort: (a: UserType, b: UserType) => (b.lastShared?.valueOf() ?? 0) - (a.lastShared?.valueOf() ?? 0),
	},
	{
		value: ["last_online", "asc"],
		label: "Last Online Ascending",
		sort: (a: UserType, b: UserType) => (a.lastLogin?.valueOf() ?? 0) - (b.lastLogin?.valueOf() ?? 0),
	},
	{
		value: ["last_online", "desc"],
		label: "Last Online Descending",
		sort: (a: UserType, b: UserType) => (b.lastLogin?.valueOf() ?? 0) - (a.lastLogin?.valueOf() ?? 0),
	},
] as const;

const sortMap = {
	alphabetical: "last_name",
	last_shared: "last_shared",
	last_online: "last_login",
};

type FilterTypes = "activity" | "connected" | "disconnected" | "groups" | "roles" | "virtualAssistant";

const networks = Object.keys(userAccountNames).map(key => ({label: userAccountNames[key], value: key}));

const filterOptions: Group<FilterTypes, string | number>[] = [
	{
		label: "Virtual Assistant",
		value: "virtualAssistant",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Roles",
		value: "roles",
		options: roleOptions,
	},
	{
		label: "Activity",
		value: "activity",
		options: [
			{label: "Never Logged In", value: "no_login"},
			{label: "Never Shared", value: "no_share"},
			{label: "Shared at least Once", value: "share"},
			{label: "Expired Networks", value: "expired_network"},
		],
	},
	{
		label: "Networks Connected",
		value: "connected",
		selectAllLabel: "All Networks",
		options: networks,
	},
	{
		label: "Networks Disconnected",
		value: "disconnected",
		selectAllLabel: "All Networks",
		options: networks,
	},
	{
		label: "Groups",
		value: "groups",
		options: [],
	},
];
export const Users = (): ReactElement => {
	const me = useMyUser();
	const {groups: groupList} = useGroupList();
	const [filter, setFilter] = useState<Record<FilterTypes, (string | number)[]>>({
		activity: [],
		connected: [],
		disconnected: [],
		groups: [],
		roles: [],
		virtualAssistant: [],
	});
	const [sort, setSort] = useState<typeof sortOptions[number]["value"]>(sortOptions[0].value);
	const [userMultiple, setUserMultiple] = useState(false);
	const [search, setSearch] = useState("");
	const [selected, setSelected] = useState<number[]>([]);
	const debouncedSearch = useDebounce(search, 500);

	const pendingUsers = usePendingUsers();
	const expiredUsers = useExpiredSocialMediaUsers();
	const invites = pendingUsers.map(u => ({id: u.id, title: u.fullName, description: u.email}));
	const reconnects = expiredUsers.map(u => ({id: u.id, title: u.fullName, description: u.email}));
	const {post} = useToastApi();
	const toast = useToast();

	const {open: openAddUser, modal, close} = useModal({});
	const openUserSingle = useCallback(() => {
		setUserMultiple(false);
		openAddUser();
	}, [setUserMultiple, openAddUser]);
	const openUserMultiple = useCallback(() => {
		setUserMultiple(true);
		openAddUser();
	}, [setUserMultiple, openAddUser]);

	const empty = useMemo(
		() =>
			search || Object.keys(filter).some(key => filter[key].length > 0) ? (
				<div className={styles.noUsers}>
					<div>
						<Icon icon="person" height={114} />
						<h3>No users match your filters.</h3>
					</div>
				</div>
			) : (
				<div className={styles.noUsers}>
					<div>
						<Icon icon="person" height={114} />
						<h3>It seems there are no users yet.</h3>
						<Span1 color="grey">Add users by clicking the button below.</Span1>
						<Button icon="add-user" value="Add Users" onClick={openUserMultiple} />
					</div>
				</div>
			),
		[search, filter, openUserMultiple]
	);
	const variables = useMemo(
		() => ({
			search: debouncedSearch,
			sort: {type: sort[0].toUpperCase(), direction: sort[1].toUpperCase()},
			filter: Object.keys(filter).reduce((acc, key) => {
				if (filter[key].length > 0) {
					if (key === "virtualAssistant") {
						acc[key] = filter[key].includes("enabled");
					} else {
						acc[key] = filter[key].map(el => (typeof el === "string" ? el.toUpperCase() : el));
					}
				}
				return acc;
			}, {}),
		}),
		[debouncedSearch, sort, filter]
	);
	const handleSelect = useCallback((id: number) => setSelected(c => toggle(id, c)), [setSelected]);

	const renderUser = useCallback(
		user => (
			<User key={user.id} user={user} onSelect={handleSelect} selected={selected.some(v => v === user.id)} />
		),
		[selected, handleSelect]
	);

	const {handleScroll, render, data} = usePaginatedQuery<UserQuery>(GET_USERS, {
		loadingProps,
		inflateItem: loadUser,
		variables,
		renderItem: renderUser,
		empty,
	});
	const selections = useMemo(
		() =>
			data
				?.filter(u => selected.includes(u.id))
				.map(u => ({id: u.id, title: u.fullName, description: u.email})),
		[data, selected]
	);

	const [deactivateUsers, {loading: deleting}] = useMutationToast(DEACTIVATE_USERS);
	const [reinviteUsers, {loading: reinviting}] = useMutationToast(REINVITE_USERS);
	const [remindReconnect, {loading: reminding}] = useMutationToast(REMIND_RECONNECT);
	const confirmDelete = useCallback(
		(s, close) =>
			deactivateUsers({
				variables: {ids: s.map(u => u.id)},
				onCompleted: () =>
					toast({
						text: "You've deactivated the users successfully",
						color: "green",
					}),
			}).then(() => {
				setSelected([]);
				return close();
			}),

		[deactivateUsers, toast]
	);
	const confirmReinvite = useCallback(
		(s, close) =>
			reinviteUsers({
				variables: {ids: s.map(u => u.id)},
				onCompleted: () =>
					toast({
						text:
							"Your welcome emails were sent. Please allow a few minutes for the message to appear in the inboxes.",
						color: "green",
					}),
			}).then(close),
		[reinviteUsers, toast]
	);
	const confirmReconnect = useCallback(
		(s, close) => remindReconnect({variables: {ids: s.map(u => u.id)}}).then(close),
		[remindReconnect]
	);

	const {open: enroll, modal: modalEnroll} = useModal({});

	const {open: deactivate, close: closeModal} = useSelectionConfirmModal({
		onConfirm: confirmDelete,
		selections,
		title: "Confirm De-Activation",
		confirming: deleting,
		renderBody: selections => (
			<>
				<P>
					You are about to de-activate{" "}
					<Span color="pink">
						{selections.length} {selections.length === 1 ? "user" : "users"}
					</Span>
					. This action will prevent these users from accessing the platform and their data will be archived.
					You can take a look at the users below before de-activating them.
				</P>
				<P>
					{" "}
					Upload a file containing multiple users that you’d like to de-activate. To get started, download
					<a href="/Users_Deactivate.csv"> our CSV file</a>.
				</P>
				<File
					what="a file"
					disabled={selected.length > 0}
					loading={deleting}
					onFile={onFileUpload}
					accept={{"text/csv": [".csv"]}}
				/>
			</>
		),
	});
	const {open: reinvite} = useSelectionConfirmModal({
		onConfirm: confirmReinvite,
		selections: invites,
		title: "Resend Invite",
		confirming: reinviting,
		confirmText: "Send",
		renderBody: renderReinvite,
		limit: 100,
	});
	const {open: reconnect} = useSelectionConfirmModal({
		onConfirm: confirmReconnect,
		selections: reconnects,
		title: "Reconnect Social Media",
		confirming: reminding,
		confirmText: "Send",
		renderBody: renderReconnect,
	});

	const onFileUpload = useCallback(
		([file]: File[]) =>
			deactivateUsers({
				variables: {file},
				onCompleted: () =>
					toast({
						text: "You've deactivated the users successfully",
						color: "green",
					}),
			}).then(() => closeModal()),
		[closeModal, deactivateUsers, toast]
	);

	const filters = useMemo(
		() => filterOptions.map(f => (f.value === "groups" ? {...f, options: groupList} : f)),
		[groupList]
	);

	const manageOptions: IconOption[] = [
		{
			icon: "add",
			label: "Add New User",
			onClick: openUserSingle,
		},
		{
			icon: "add-user",
			label: "Add Multiple Users",
			onClick: openUserMultiple,
		},
		{
			icon: "link",
			label: "Self-Enrollment Link",
			onClick: enroll,
		},
		{
			icon: "export",
			label: "Export Users",
			onClick: () =>
				post(
					"export users",
					config.exportUrl,
					{
						type: "users",
						orgId: me.org.id,
						userId: me.id,
						...(filter.roles.length > 0 && {roles: filter.roles.join(",")}),
						...(filter.groups.length > 0 && {group: filter.groups}),
						...((filter.connected.length > 0 || filter.disconnected.length > 0) && {
							connections: [
								...filter.connected,
								...filter.disconnected.map(connection => `no-${connection}`),
							],
						}),
						...(filter.activity?.includes("no_login") && {status: "pending"}),
						...(filter.activity?.includes("expired_network") && {connections: "expired"}),
						sort: `${sortMap[sort[0]] || sort[0]} ${sort[1].toUpperCase()}`,
					},
					() =>
						toast({
							text:
								"Your request to export users is currently being processed. You will receive an email once processing is complete.",
							color: "green",
						})
				),
		},
		{icon: "delete", label: "De-Activate Users", onClick: deactivate},
	];

	const contactOptions: IconOption[] = [];
	if (invites.length) contactOptions.push({label: `Resend Invite (${invites.length})`, onClick: reinvite});
	if (reconnects.length)
		contactOptions.push({label: `Reconnect Social (${reconnects.length})`, onClick: reconnect});

	const hasUsers = useMemo(() => (reconnects?.length || invites?.length) !== 0, [reconnects, invites]);

	usePageScroll(handleScroll);

	return (
		<>
			<InputHeader className={styles.inputHeader}>
				<>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
					<GroupedDropdown groups={filters} onChange={setFilter} value={filter} icon="filter" invert />
					<DropdownButton
						options={sortOptions.map(o => ({
							label: o.label,
							onClick: () => setSort(o.value),
							selected: o.value === sort,
						}))}
						icon="sort"
						invert
					/>
				</>
				<>
					{hasUsers && contactOptions.length > 0 && (
						<DropdownButton options={contactOptions} value="Contact" arrow invert />
					)}

					<DropdownButton options={manageOptions} value="Manage" arrow />
				</>
			</InputHeader>
			<Separator horizontal />
			{!!data.length && (
				<InputRow className={styles.padding}>
					<Checkbox
						value={triState(data, selected)}
						onChange={v => setSelected(v ? data.map(u => u.id) : [])}
					/>
					{selected.length > 0 && (
						<>
							<Separator />
							<SmallButton
								value="De-activate Users"
								icon="delete"
								onClick={deactivate}
								color="pink"
								border={false}
								invert
							/>
							<span></span>
						</>
					)}
				</InputRow>
			)}
			{render}

			<EnrollModal modal={modalEnroll} />
			<AddUserModal
				modal={modal}
				onEnroll={() => {
					close();
					enroll();
				}}
				viewMultiple={userMultiple}
			/>
		</>
	);
};
