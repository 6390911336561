import React, {useCallback, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import {count} from "../../utils/text";
import {Collection} from "../../data";
import {Card} from "../../components/card";
import {Span, Span4} from "../../components/text";
import {UserAvatar} from "../../components/user-avatar";
import {DropdownButton, InputRow} from "../../components/input";
import {useMutationToast} from "../../toast";
import {getCollectionStatusBadge} from ".";
import {Badge} from "../../components/badge";
import {useConfirmDeleteModal, useConfirmRevokeModal} from "../../modals";
import {
	DELETE_COLLECTION,
	DUPLICATE_COLLECTION,
	SAVE_COLLECTION,
	STOP_ALL_FUTURE_SHARES,
} from "../../data/collection";
import {useSimpleGroup} from "../../data/group";

import styles from "./collection-item.module.scss";

interface CollectionProps {
	collection: Collection;
}

export const CollectionItem = ({collection: {id, ...collection}}: CollectionProps): React.ReactElement => {
	const scheduledApprovedCollection = useMemo(
		() => collection.status === "approved" && collection.scheduledFor?.isBefore(),
		[collection.scheduledFor, collection.status]
	);
	const navigate = useNavigate();
	const {groups} = useSimpleGroup();
	const gids = collection.to.groupIds;

	const post = collection.opengraphData;
	const [deleteCollection, {loading: deleting}] = useMutationToast(DELETE_COLLECTION, {variables: {id}});
	const [stopAllFutureShares, {loading: stoppingShares}] = useMutationToast(STOP_ALL_FUTURE_SHARES, {
		variables: {id: id},
	});
	const [duplicateCollection] = useMutationToast(DUPLICATE_COLLECTION, {variables: {id}});
	const [updateCollection] = useMutationToast(SAVE_COLLECTION);

	const removeHTMLTags = str => str.replace(/<\/?[^>]+(>|$)/g, "");

	const trimmedMessage = collection.message ? removeHTMLTags(collection.message) : "No Description";

	const handleDelete = useCallback(close => deleteCollection().then(close), [deleteCollection]);
	const deleteModal = useConfirmDeleteModal({
		what: collection.title,
		onDelete: handleDelete,
		deleting: deleting,
	});
	const revokeModal = useConfirmRevokeModal({
		what: collection.title,
		onConfirm: close => stopAllFutureShares().then(close),
		confirming: stoppingShares,
	});

	const handleApprove = useCallback(
		() =>
			updateCollection({
				variables: {id, changes: {status: "APPROVED"}},
			}),
		[id, updateCollection]
	);
	const handleDraft = useCallback(
		() =>
			updateCollection({
				variables: {id, changes: {status: "DRAFT"}},
			}),
		[id, updateCollection]
	);
	return (
		<Card className={styles.container} onClick={() => navigate(`/collections/${id}`)}>
			<InputRow position="between">
				<Badge {...getCollectionStatusBadge({id, ...collection})} />
				<DropdownButton
					icon="ellipsis"
					invert
					color="black"
					border={false}
					options={
						collection.status === "draft"
							? [
									{
										label: "View or edit",
										onClick: () => navigate(`/collections/${id}`),
									},
									{label: "Duplicate", onClick: () => duplicateCollection()},
									{
										label: "Approve",
										disabled:
											collection.to.groupIds.length + collection.to.slackChannels.length === 0 ||
											!collection.scheduledFor,
										onClick: handleApprove,
										...(collection.to.groupIds.length + collection.to.slackChannels.length === 0
											? {hint: "Complete required fields"}
											: {}),
									},
									{label: "Delete", onClick: deleteModal.open},
							  ]
							: [
									{
										label: collection.sent ? "View" : "View or edit",
										onClick: () => navigate(`/collections/${id}`),
									},
									{label: "Duplicate", onClick: () => duplicateCollection()},
									{
										label: "Revert to Draft",
										onClick: handleDraft,
										disabled: scheduledApprovedCollection,
									},
									{
										label: collection.sent ? "Revoke" : "Delete",
										onClick: collection.sent ? revokeModal.open : deleteModal.open,
									},
							  ]
					}
				/>
			</InputRow>
			{collection.title && (
				<Span size={1} color="blue" trim={1}>
					{collection.title}
				</Span>
			)}
			<h6>
				{count(collection.postsCount, "Post")} &middot; {collection.updated ? "Modified" : "Created"}{" "}
				{collection.updated?.fromNow() ?? collection.created.fromNow()}
			</h6>
			<Span trim={2}>{trimmedMessage}</Span>
			<div className={styles.articleContainer}>
				{post?.video ? (
					<video className={styles.video} controls muted>
						<source src={post.video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				) : post?.image ? (
					<img src={post.image} alt={post.description} />
				) : (
					<img src="/default-image.png" alt="" />
				)}
			</div>

			<UserAvatar size="extraSmall" userId={collection.owner} name />
			<Span4 color="grey" trim={1}>
				{gids.length
					? groups
							.filter(g => gids.includes(g.value))
							.map(c => c.label)
							.join(", ")
					: "No groups selected"}
			</Span4>
			{collection.scheduledFor &&
				!collection.sent &&
				(scheduledApprovedCollection ? (
					<Span4 color={"pink"}>Sending will begin shortly...</Span4>
				) : (
					<Span4>Scheduled Delivery Time: {collection.scheduledFor.formatAs("numDateAndTime")}</Span4>
				))}
			{collection.sent && <Span4>Delivered {collection.sent.formatAs("shortDateAndTime")}</Span4>}
		</Card>
	);
};
