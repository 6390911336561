import {FC, useCallback, useEffect, useMemo} from "react";
import {useQuery} from "@apollo/client";
import dayjs from "dayjs";
import classNames from "classnames";

import {EmployeeAdvocacyFilter} from "./index";
import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {Span, Span1, Span2, Span4, P1} from "../../../components/text";
import {Card} from "../../../components/card";
import {DoughnutChart} from "../../../components/chart/doughnut-chart";
import {ChartData} from "../../../components/chart";
import {
	GET_TOTAL_ACTIVE_USERS,
	GET_USER_ADOPTION_STATS,
	REINVITE_USERS,
	usePendingUsers,
} from "../../../data/user";
import {Button} from "../../../components/input";
import {useMutationToast} from "../../../toast";
import {Loading} from "../../../components/loading";
import {Icon} from "../../../components/images";
import {useModal} from "../../../modals/new";
import {PendingUsersModal} from "./pending-users-modal";
import {useConfirmModal} from "../../../modals";

import styles from "./employee-advocacy.module.scss";

const minDate = dayjs().add(-10, "year").startOf("year");
const calculatePercentage = (value: number, total: number) => {
	if (value === 0 || total === 0) return 0;
	const percentage = (value / total) * 100;
	return percentage % 1 === 0 ? Math.abs(percentage).toFixed(0) : Math.abs(percentage).toFixed(1);
};

export const UserAdoptionCards: FC<{filter: EmployeeAdvocacyFilter}> = ({filter}) => {
	const {open, modal} = useModal({});
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const pendingUsers = usePendingUsers();
	const {data: totalActiveUsersData} = useQuery(GET_TOTAL_ACTIVE_USERS);
	const {data, loading} = useQuery(GET_USER_ADOPTION_STATS, {variables: {start, end}});
	const [reinviteUsers, {loading: reinviting, data: reinviteUsersData}] = useMutationToast<{
		reinviteUsers: number[];
	}>(REINVITE_USERS);
	const adoptionStats = useMemo(
		() => ({
			sharedOnce: data?.adoptionStats?.filter(user => user.status === "SHARED_ONCE").length ?? 0,
			sharedMultiple: data?.adoptionStats?.filter(user => user.status === "SHARED_MULTIPLE").length ?? 0,
			inactive: data?.adoptionStats?.filter(user => user.status === "NO_ACTIVITY").length ?? 0,
		}),
		[data]
	);
	const totalUsers = totalActiveUsersData?.totalActiveUsers ?? 0;
	const chartDataValues = Object.values(adoptionStats).map(value => calculatePercentage(value, totalUsers));
	const chartData = [
		{
			data: chartDataValues.reduce((s: number, i) => s + Number(i), 0) ? chartDataValues : [0, 0, 0, 0, 1],
			color: "blue-primary",
			backgroundColor: ["#36AEEF", "#e82980", "#DCE0E7", "#F5F5F5"],
		},
	] as ChartData[];
	const pendingUsersIds = pendingUsers.map(user => user.id);
	const invitationSentToUsers = useMemo(
		() => !pendingUsersIds?.some(userId => !reinviteUsersData?.reinviteUsers?.includes(userId)),
		[pendingUsersIds, reinviteUsersData?.reinviteUsers]
	);
	const resendInvitation = useCallback(() => {
		const userIds =
			pendingUsersIds
				?.filter(userId => !reinviteUsersData?.reinviteUsers?.includes(userId))
				?.map(userId => userId) ?? [];

		if (!userIds?.length) {
			return;
		}

		userIds
			.reduce((acc, userId) => {
				if (!acc.length || acc[acc.length - 1]?.length === 100) {
					acc.push([]);
				}
				acc[acc.length - 1].push(userId);

				return acc;
			}, [] as number[][])
			.forEach(userIds => reinviteUsers({variables: {ids: userIds}}));
	}, [pendingUsersIds, reinviteUsersData?.reinviteUsers, reinviteUsers]);
	const usersText = totalUsers === 1 ? "User" : "Users";

	const confirmModal = useConfirmModal(
		() => ({
			title: "Resend invites",
			body: (
				<P1>
					Are you sure you want to resend invites to {pendingUsersIds?.length} pending{" "}
					{pendingUsersIds?.length <= 1 ? "user" : "users"}?
				</P1>
			),
			confirmColor: "blue",
			confirmText: "Resend",
			confirming: reinviting,
			onConfirm: resendInvitation,
		}),
		[pendingUsersIds?.length, reinviting, resendInvitation]
	);

	useEffect(() => {
		!reinviting && confirmModal.close();
	}, [confirmModal, reinviting]);
	return (
		<div className={styles.cardsContainer}>
			<Card className={classNames(styles.card, styles.adoptionCard)}>
				<div className={styles.cardHeader}>
					<div className={styles.sameLine}>
						<h3>User Adoption</h3>
						<Span4 color={"grey"}>{`${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`}</Span4>
					</div>
				</div>
				<div className={styles.adoptionStats}>
					{loading ? (
						<Loading position="center" />
					) : (
						<DoughnutChart
							className={styles.doughnutChart}
							data={chartData}
							title={`${totalUsers}`}
							subtitle={usersText}
						/>
					)}
					<div className={styles.stats}>
						<div className={styles.statsLine}>
							<div className={styles.firstColumn}>
								<div className={styles.statsColor} style={{backgroundColor: "#36AEEF"}}></div>
								<div className={styles.label}>
									<Span1 bold>Active Users</Span1>
									<Span1 color={"grey"}>(one share)</Span1>
								</div>
							</div>
							<div className={styles.secondColumn}>
								<Span className={styles.percentage}>
									{calculatePercentage(adoptionStats.sharedOnce, totalUsers)}%
								</Span>
								<Span1 color="grey">
									{adoptionStats.sharedOnce} out of {totalUsers} {usersText.toLowerCase()}
								</Span1>
							</div>
						</div>
						<div className={styles.statsLine}>
							<div className={styles.firstColumn}>
								<div className={styles.statsColor} style={{backgroundColor: "#E82980"}}></div>
								<div className={styles.label}>
									<Span1 bold>Power Users</Span1>
									<Span1 color={"grey"}>(multiple shares)</Span1>
								</div>
							</div>
							<div className={styles.secondColumn}>
								<Span className={styles.percentage}>
									{calculatePercentage(adoptionStats.sharedMultiple, totalUsers)}%
								</Span>
								<Span1 color="grey">
									{adoptionStats.sharedMultiple} out of {totalUsers} {usersText.toLowerCase()}
								</Span1>
							</div>
						</div>
						<div className={styles.statsLine}>
							<div className={styles.firstColumn}>
								<div className={styles.statsColor} style={{backgroundColor: "#DCE0E7"}}></div>
								<div className={styles.label}>
									<Span1 bold>Inactive Users</Span1>
									<Span1 color={"grey"}>(no activity)</Span1>
								</div>
							</div>
							<div className={styles.secondColumn}>
								<Span className={styles.percentage}>
									{calculatePercentage(adoptionStats.inactive, totalUsers)}%
								</Span>
								<Span1 color="grey">
									{adoptionStats.inactive} out of {totalUsers} {usersText.toLowerCase()}
								</Span1>
							</div>
						</div>
					</div>
				</div>
			</Card>
			<Card className={classNames(styles.card, styles.pendingCard)}>
				<div className={styles.cardHeader}>
					<div className={styles.sameLine}>
						<h3>Pending Users</h3>
					</div>
					{!!pendingUsersIds?.length && <Span color={"blue"} onClick={open} bold>{`See all`}</Span>}
				</div>
				<div className={styles.cardContent}>
					<h1>{`${pendingUsersIds?.length} ${pendingUsersIds?.length === 1 ? "User" : "Users"}`}</h1>
					{!!pendingUsersIds?.length &&
						(invitationSentToUsers ? (
							<div className={styles.invitationSentMsg}>
								<Icon width={16} height={16} icon="circle-check" />
								<Span2 bold>Invitations sent</Span2>
							</div>
						) : (
							<Button
								color="blue"
								invert
								border={false}
								loading={reinviting}
								value={`Resend ${pendingUsersIds?.length} invitations`}
								onClick={confirmModal.open}
								icon="refresh"
							/>
						))}
				</div>
				<PendingUsersModal modal={modal} users={pendingUsers} />
			</Card>
		</div>
	);
};
