import {KeyboardEvent as RKE, MouseEvent, ReactNode, RefObject, useCallback, useEffect, useMemo} from "react";
import {Portal} from "react-portal";
import {flip, size, autoUpdate, useFloating} from "@floating-ui/react-dom";
import classnames, {Argument} from "classnames";

import {useStateRef} from "../../state-ref";

interface DropdownOptions {
	disabled?: boolean;
	focusRef?: RefObject<HTMLElement>;
	onClose?: () => void;
	onKeyDown?: (e: RKE<HTMLDivElement>, fns: DropdownCallback) => void;
	onMouseOut?: (e: MouseEvent<HTMLDivElement>) => void;
	portalClassName?: Argument;
	popup: (fns: DropdownCallback) => ReactNode;
	minHeight?: number;
}

export interface DropdownCallback {
	close: () => void;
}

interface DropdownHook {
	close: () => void;
	isOpen: boolean;
	open: () => void;
	portal: ReactNode;
	reference: (ref: HTMLElement | null) => void;
	toggle: () => void;
}

export const useDropdown = ({
	disabled,
	focusRef,
	onClose,
	onKeyDown,
	onMouseOut,
	popup,
	portalClassName,
	minHeight,
}: DropdownOptions): DropdownHook => {
	const [isOpen, setIsOpen, openRef] = useStateRef(false);
	const floating = useFloating<HTMLElement>({
		placement: "bottom-start",
		whileElementsMounted: autoUpdate,
		middleware: [
			flip({padding: 10}),
			size({
				apply({availableHeight, elements, rects}) {
					let el: Element | null = elements.reference as Element;
					let zIndex: number | undefined;
					while (el) {
						const elZIndex = parseInt(
							document.defaultView?.getComputedStyle(el, null)?.getPropertyValue("z-index") ?? "",
							10
						);
						if (elZIndex > (zIndex ?? 0)) zIndex = elZIndex;

						el = el.parentElement;
					}
					const height = Math.max(availableHeight - 20, minHeight ?? 0);
					Object.assign(elements.floating.style, {
						maxHeight: `${height}px`,
						minWidth: `${rects.reference.width}px`,
						overflowY: "auto",
						zIndex,
					});
				},
				padding: 10,
			}),
		],
	});

	useEffect(() => {
		if (isOpen) (focusRef ?? floating.refs.floating).current?.focus();
	}, [floating.refs.floating, focusRef, isOpen]);

	const close = useCallback(() => {
		setIsOpen(false);
		onClose?.();
	}, [onClose, setIsOpen]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (!openRef.current) return;
			switch (e.key) {
				case "Tab":
				case "Escape":
					close();
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		};

		const handleClick = ({target}): void => {
			if (floating.refs.floating.current?.contains?.(target)) return;
			if (floating.refs.reference.current?.contains?.(target)) return;
			if (openRef.current) close();
		};

		document.addEventListener("keydown", handleKeyDown, true);
		document.addEventListener("click", handleClick, true);
		return () => {
			document.removeEventListener("keydown", handleKeyDown, true);
			document.removeEventListener("click", handleClick, true);
		};
	}, [close, floating.refs, openRef, setIsOpen]);

	return useMemo(
		(): DropdownHook => ({
			close,
			isOpen,
			open: () => !disabled && setIsOpen(true),
			portal: isOpen && (
				<Portal>
					<div
						className={classnames(portalClassName)}
						ref={floating.refs.setFloating}
						onKeyDown={e => onKeyDown?.(e, {close})}
						onMouseOut={
							onMouseOut &&
							(e => {
								let node = e.target as HTMLElement | null;
								while (node) {
									if (node === floating.refs.floating.current || node === floating.refs.reference.current)
										return;
									node = node.parentElement;
								}
								return onMouseOut(e);
							})
						}
						style={{
							position: floating.strategy,
							top: floating.y ?? 0,
							left: floating.x ?? 0,
						}}
						tabIndex={0}
					>
						{popup({close})}
					</div>
				</Portal>
			),
			reference: floating.refs.setReference,
			toggle: () => setIsOpen(c => (disabled ? false : !c)),
		}),
		[close, disabled, floating, isOpen, onKeyDown, onMouseOut, popup, portalClassName, setIsOpen]
	);
};
